<script>
import { defineComponent, onMounted, reactive } from "vue";
import s1 from "../assets/img/award-tour/s-1.png";
import s2 from "../assets/img/award-tour/s-2.png";
import s3 from "../assets/img/award-tour/s-3.png";
import s4 from "../assets/img/award-tour/s-4.png";
import request from "../utils/request";
export default defineComponent({
  setup() {
    const baseOss = `http://benqgroup.oss-cn-hangzhou.aliyuncs.com/design-competition/design-competition-resources/`;
    const getPicOss = (name) => `${baseOss}${name}`;
    const stationList = reactive([
      {
        sImg: s1,
        location: "江苏省南通市",
        time: "2023.10.30-11.04",
        des: "在第五届东布洲国际动画周的动画市场单元，明基PD系列和SW系列显示器布展，展映2023明基设计大赛动画组获奖作品。",
        video: "1",
        imgs: [getPicOss("s-1-1.jpg"), getPicOss("s-1-2.jpg")],
      },
      {
        sImg: s2,
        location: "湖南省长沙市",
        time: "2023.11.10-11.12",
        des: "在第二届湖南数学文化产业嘉年华的现场，与CG模型网等多个品牌进行CG数字艺术联合展，使用PD 05系列4K显示器展出了2023明基设计大赛CG组、动画组获奖作品。",
        video: "1",
        imgs: [getPicOss("s-2-1.jpg"), getPicOss("s-2-2.jpg")],
      },
      {
        sImg: s3,
        location: "北京市",
        time: "2023.11.10-11.13",
        des: "第五届费那奇动画周开设灵感市集板块，明基PD 06系列入驻摊位，用大小屏结合的方式展示2023明基设计大赛动画获奖作品",
        video: "1",
        imgs: [getPicOss("s-3-1.jpg"), getPicOss("s-3-2.jpg")],
      },
      {
        sImg: s4,
        location: "贵州省贵阳市",
        time: "2023.11.20-11.22",
        des: "在贵州人文科技学院现场，与大赛评委刘兵克合作，使用PD系列4K屏幕联合展示刘兵克字体奖和本届明基设计大赛获奖作品。",
        imgs: [
          getPicOss("s-4-1.jpg"),
          getPicOss("s-4-2.jpg"),
          getPicOss("s-4-3.jpg"),
        ],
      },
    ]);
    const lastStation = reactive([
      {
        video: "1",
        des: "明基 PD2705U PD 系列定义专业设计显示器标准",
      },
      {
        video: "1",
        des: "明基 PD2706U 支持全链路色准，创意加速，不二之选",
      },
    ]);
    const videoSrcTask = request.get("/entry/getDisplayUrl").then((v) => {
      const data = v.data.data;
      stationList[0].video = data[0].videoId;
      stationList[1].video = data[1].videoId;
      stationList[2].video = data[2].videoId;
      lastStation[0].video = data[3].videoId;
      lastStation[1].video = data[4].videoId;
    });

    onMounted(() => {
      videoSrcTask.then(() => {
        const intersectionObserver = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              const video = entry.target;
              console.log(video);
              if (video) {
                if (entry.isIntersecting) {
                  console.log("play");
                  video.play();
                } else {
                  console.log("pause");
                  video.pause();
                }
              }
            });
          },
          {
            threshold: 0.5,
          }
        );
        document.querySelectorAll(".station .video").forEach((video) => {
          intersectionObserver.observe(video);
        });
      });
    });

    return {
      stationList,
      lastStation,
    };
  },
});
</script>
<template>
  <div class="award-tour">
    <div class="main">
      <div class="bg-svg">
        <img
          src="../assets/img/award-tour/arrow-bg.svg"
          style="width: 100%; height: 100%"
        />
        <img
          src="../assets/img/award-tour/triangle.png"
          class="triangle"
          style="top: 376px; left: 0"
        />
        <img
          src="../assets/img/award-tour/triangle.png"
          class="triangle"
          style="top: 773px; left: 1345px; transform: rotate(-90deg)"
        />
        <img
          src="../assets/img/award-tour/triangle.png"
          class="triangle"
          style="top: 1253px; left: 1380px"
        />
        <img
          src="../assets/img/award-tour/triangle.png"
          class="triangle"
          style="top: 1923px; left: 0"
        />
        <img
          src="../assets/img/award-tour/triangle.png"
          class="triangle"
          style="top: 2453px; left: 1380px"
        />
      </div>
      <div class="station" v-for="(item, index) in stationList" :key="index">
        <div class="headline row">
          <img :src="item.sImg" class="s-img" />
          <div class="split"></div>
          <div class="right">
            <div class="row" style="align-items: center">
              <img class="icon" src="../assets/img/award-tour/location.png" />
              <span class="text-1">{{ item.location }}</span>
              <img class="icon" src="../assets/img/award-tour/clock.png" />
              <span class="text-1">{{ item.time }}</span>
            </div>
            <div class="des text-2">{{ item.des }}</div>
          </div>
        </div>
        <div
          class="show"
          style="align-items: center; justify-content: space-between"
        >
          <video
            :id="`video${index}`"
            controls
            v-if="item.video"
            class="video"
            :src="item.video"
          ></video>
          <div class="imgs" :class="item.video ? 'v' : 'h'">
            <img
              v-for="(img, i_d) in item.imgs"
              :src="img"
              class="img"
              :key="`img-${index}-${i_d}`"
            />
          </div>
        </div>
      </div>
      <img
        src="../assets/img/award-tour/more.png"
        alt=""
        class="s-img"
        style="margin: 143px 0 211px 0"
      />
      <div class="station">
        <div class="headline row">
          <img
            src="../assets/img/award-tour/machine.png"
            alt=""
            class="s-img"
          />
          <div class="split"></div>
          <div class="des text-2">
            明基 PD
            系列专业设计显示器以二十余年专业屏幕研发经验为基础，全面把握用户需求变迁，通过严格调校显示色彩、精心打磨人体工学设计、精准匹配不同场景的使用模式,为专业创意工作者带来尽展所长的灵感工具。尤其
            AOCOLOR
            专有技术的问世，令每台显示器都可呈现丰富准确的色彩和均匀一致的显示效果,实现“开箱即色准”的卓越体验。
          </div>
        </div>
        <div
          class="show"
          style="align-items: center; justify-content: space-between"
        >
          <div
            class="video-wrapper"
            v-for="(item, index) in lastStation"
            :key="index"
          >
            <video
              controls
              style
              :src="item.video"
              :style="{
                width: '590px',
                height: '332px',
                backgroundColor: '#fff',
              }"
            ></video>
            <div
              class="bar text-2"
              style="
                background-color: #001c4d;
                text-align: center;
                line-height: 56px;
                margin-top: -4px;
              "
              v-text="item.des"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="less">
.award-tour {
  background-image: url("../assets/img/detailPic/bg.png");
  width: 100%;
  padding: 154px 0 253px;
  color: white;
  font-family: -apple-system, BlinkMacSystemFont, Helvetica Neue, PingFang SC,
    Microsoft YaHei, Source Han Sans SC, Noto Sans CJK SC, WenQuanYi Micro Hei,
    sans-serif;
  overflow: visible;
}
.main {
  width: 1200px;
  margin: 0 auto 0;
  position: relative;
  overflow: visible;
  .triangle {
    width: 30px;
    height: 20px;
    position: absolute;
  }
  .bg-svg {
    opacity: 0.9;
    width: 1400px;
    height: 3300px;
    position: absolute;
    top: 0;
    left: -100px;
  }
}
.text-1 {
  // font-size: 24px;
  font-size: 29px;
}
.text-2 {
  font-size: 16px;
}
.split {
  background-color: #abbfd8;
  width: 2px;
  height: 66px;
  margin: 5px 33px 0;
}
.row {
  display: flex;
  flex-flow: row nowrap;
}
.s-img {
  height: 72px;
  object-fit: contain;
}
.station {
  margin-bottom: 154px;
  .headline {
    align-items: start;
    .right {
      margin-top: 2px;
      .icon {
        width: 22px;
        object-fit: contain;
        margin-right: 10px;
      }
      .text-1 {
        margin-right: 40px;
      }
      .des {
        margin-top: 12px;
        max-width: 966px;
        text-wrap: wrap;
        letter-spacing: 0;
      }
    }
  }
  .show {
    margin-top: 40px;
    display: flex;
    flex-flow: row nowrap;
    .video-wrapper {
      border-radius: 8px;
      overflow: hidden;
    }
    .video,
    .img {
      object-fit: contain;

      // background-color: #b7d5fa;
      border-radius: 8px;
    }
    .video {
      width: 800px;
      height: 450px;
      margin-right: 20px;
    }
    .imgs {
      flex: 9;
      display: flex;

      justify-content: space-between;
      &.h {
        flex-flow: row wrap;
      }
      &.v {
        flex-flow: column wrap;
        .img:first-child {
          margin-bottom: 20px;
        }
      }
      .img {
        width: 382px;
        height: 215px;
      }
    }
  }
}
</style>
